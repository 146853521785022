<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="3">
              <label for="month">Month</label>
              <b-form-select
                id="month"
                v-model="target.month"
                :options="months"
                :disabled="target.id ? true : false"
              ></b-form-select>
            </b-col>
            <b-col cols="3">
              <label for="year">Year</label>
              <b-form-select
                id="year"
                v-model="target.year"
                :options="years"
                :disabled="target.id ? true : false"
              >
              </b-form-select>
            </b-col>
            <!-- <b-col cols="3" class="mb-3">
                            <label for="selectArea">Select Area</label>
                            <b-form-select
                                v-model="form.area"
                                id="selectArea"
                                :options="area">
                            </b-form-select>
                        </b-col>
                        <b-col cols="3" class="mb-3">
                            <label for="territory">Select Territory</label>
                            <b-form-select
                            v-model="form.territory"
                                id="territory"
                                :options="territory">
                            </b-form-select>
                        </b-col> -->
          </b-row>
        </div>
      </div>
      <br />
      <b-row>
        <b-col cols="12" v-for="(channel, key) in target.channels" :key="key" class="mb-4">
          <div class="card">
            <div class="card-body">
              
              <span style="font-size: 20px;padding: 5px 20px;background-color: yellow;color: #000;border-radius: 20px; font-weight: 600;">{{ channel.type }}</span>

              <h5 style="margin-top: 20px;color: cadetblue;font-weight: 600;">Product wise Targets</h5>
              <table class="table" style="border: 2px solid #316606; width: 100%;">
                <!-- <thead>
                  <th style="width: 24%">Category</th>
                  <th style="width: 24%">Product</th>
                  <th style="width: 24%">Target PCS</th>
                  <th style="width: 24%">Target Amount</th>
                  <th style="width: 6%">Action</th>
                </thead> -->
                <tbody v-for="(product, pIndex) in channel.products" :key="pIndex" style="border: 2px solid #aaa;">
                  <tr>
                    <td>
                      <label>Category</label>
                      <b-form-select
                        style="border-color: cadetblue;"
                        v-model="product.category_id"
                        :options="all_categories"
                        text-field="name"
                        value-field="id"
                        @change="setCategory(product)"
                      >
                      </b-form-select>
                    </td>

                    <td>
                      <label>Product</label>
                      <b-form-select
                        style="border-color: cadetblue;"
                        v-if="allProducts[product.category_id]"
                        v-model="product.product_id"
                        :options="allProducts[product.category_id]"
                        text-field="display_name"
                        value-field="id"
                      >
                      </b-form-select>
                      <b-form-select
                        style="border-color: cadetblue;"
                        v-else
                      >
                      </b-form-select>
                    </td>

                    <td>
                      <label>Target Pcs</label>
                      <b-form-input 
                      style="border-color: cadetblue;"
                      type="number"
                      placeholder="Target Pcs"
                        v-model="product.target_pcs"
                        required
                        @input="updateTargetAmount(product)"
                      ></b-form-input>

                    </td>

                    <td>
                      <label>Target Amount</label>
                      <b-form-input
                        style="border-color: cadetblue;"
                        type="number"
                        v-model="product.target_amount"
                        placeholder="Target Amount"
                        required
                      ></b-form-input>
                    </td>

                    <td>
                      <b-button
                        v-if="channel.products.length > 1"
                        size="sm"
                        variant="danger"
                        @click="removeProductRow(channel, pIndex)"
                        >Del</b-button
                      >
                    </td>
                  </tr>
                  <tr v-if="product.areas" class="text-center">
                    <td v-for="(area, aKey) in product.areas" :key="aKey" style=" border: 1px solid black;">
                      <h6>Area {{ area.name }}</h6>
                      <table style="width: 100%; border: 1px solid black;">
                        <tr>
                          <th v-for="(terri, tIndex) in area.territories" :key="tIndex" style=" border: 1px solid black;">{{ terri.name }}</th>  
                        </tr>
                        <tr>
                          <td v-for="(newT, ntIndex) in area.territories" :key="ntIndex" style="padding: 2px 3px;  border: 1px solid black;">
                            <b-form-input
                              v-model="newT.target_pcs"
                              placeholder="Pcs"
                              style="border-color: lightskyblue;color: #000; margin-bottom: 4px;padding: 3px 4px; font-size:11px;"
                              required
                              @input="updateTargetAmount(product, newT)"
                            ></b-form-input>
                            <b-form-input
                              v-model="newT.target_amount"
                              placeholder="Amount"
                              style="border-color: lightcoral;color: #000; margin-bottom: 4px;padding: 3px 4px; font-size:11px;"
                              required
                            ></b-form-input>
                          </td>  
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="5" class="text-right">
                      <b-button size="sm" @click="addProductsRow(channel)" variant="primary"
                        >+ Add more</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
             
              <br>
              <br>

              <h5 style="color: cadetblue;font-weight: 600;">Market wise Targets</h5>
              <table class="table" style="border: 2px solid #239aaa;">
                <thead>
                  <th>Market</th>
                  <th>Target PCS</th>
                  <th>Target Amount</th>
                  <th>Action</th>
                </thead>
                <tbody v-for="(market, pIndex) in channel.markets" :key="pIndex" style="border: 2px solid #aaa;">
                  <tr>
                    <td>
                      <b-form-select
                      style="border-color: cadetblue;"
                        v-model="market.sales_market"
                        :options="all_markets"
                        text-field="sales_market"
                        value-field="sales_market"
                      >
                      </b-form-select>
                    </td>

                    <td>
                      <b-form-input
                      style="border-color: cadetblue;"
                        v-model="market.target_pcs"
                        required
                      ></b-form-input>
                    </td>

                    <td>
                      <b-form-input
                      style="border-color: cadetblue;"
                        v-model="market.target_amount"
                        required
                      ></b-form-input>
                    </td>

                    <td>
                      <b-button
                        v-if="channel.markets.length > 1"
                        size="sm"
                        variant="danger"
                        @click="removeProductRow(channel, pIndex, 'market')"
                        >Del</b-button
                      >
                    </td>
                  </tr>
                  <tr v-if="market.areas" class="text-center">
                    <td v-for="(area, aKey) in market.areas" :key="aKey" style=" border: 1px solid black;">
                      <h6>Area {{ area.name }}</h6>
                      <table style="width: 100%; border: 1px solid black;">
                        <tr>
                          <th v-for="(terri, tIndex) in area.territories" :key="tIndex" style=" border: 1px solid black;">{{ terri.name }}</th>  
                        </tr>
                        <tr>
                          <td v-for="(newT, ntIndex) in area.territories" :key="ntIndex" style="padding: 2px 3px;  border: 1px solid black;">
                            <b-form-input
                              v-model="newT.target_pcs"
                              placeholder="Pcs"
                              style="border-color: lightskyblue;color: #000; margin-bottom: 4px;padding: 3px 4px; font-size:11px;"
                              required
                            ></b-form-input>
                            <b-form-input
                              v-model="newT.target_amount"
                              placeholder="Amount"
                              style="border-color: lightcoral;color: #000; margin-bottom: 4px;padding: 3px 4px; font-size:11px;"
                              required
                            ></b-form-input>
                          </td>  
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="4" class="text-right">
                      <b-button size="sm" @click="addProductsRow(channel, 'market')" variant="primary"
                        >+ Add more</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </b-col>
      </b-row>
      <br />
      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
export default {
  name: "TargetAddEditForm",
  data() {
    let all_months = [
      {
        id: 1,
        text: "January",
        value: "January",
      },
      {
        id: 2,
        text: "February",
        value: "February",
      },
      {
        id: 3,
        text: "March",
        value: "March",
      },
      {
        id: 4,
        text: "April",
        value: "April",
      },
      {
        id: 5,
        text: "May",
        value: "May",
      },
      {
        id: 6,
        text: "June",
        value: "June",
      },
      {
        id: 7,
        text: "July",
        value: "July",
      },
      {
        id: 8,
        text: "August",
        value: "August",
      },
      {
        id: 9,
        text: "September",
        value: "September",
      },
      {
        id: 10,
        text: "October",
        value: "October",
      },
      {
        id: 11,
        text: "November",
        value: "November",
      },
      {
        id: 12,
        text: "December",
        value: "December",
      },
    ];
    return {

      target: {
        id: "",
        month: "",
        year: "",
        type: "sales",
        channels: [
          {
            type: "Corporate",
            products: [
              {
                category_id: "",
                category_name: "",
                product_id: "",
                product_name: "",
                target_pcs: "",
                target_amount: "",
              }
            ],
            markets: [
              {
                sales_market: "",
                target_pcs: "",
                target_amount: "",
              }
            ],
          },
          {
            type: "Export",
            products: [
              {
                category_id: "",
                category_name: "",
                product_id: "",
                product_name: "",
                target_pcs: "",
                target_amount: "",
              }
            ],
            markets: [
              {
                sales_market: "",
                target_pcs: "",
                target_amount: "",
              }
            ],
          },
          {
            type: "Retail",
            markets: [
              {
                sales_market: "",
                target_pcs: "",
                target_amount: "",
                areas: [
                  {
                    name: "A",
                    territories: [
                      {
                        name: "T1",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T2",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T3",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "B",
                    territories: [
                      {
                        name: "T4",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T5",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T6",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "C",
                    territories: [
                      {
                        name: "T7",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T8",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                  {
                    name: "D",
                    territories: [
                      {
                        name: "T9",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                ],
              }
            ],
            products: [
              {
                category_id: "",
                category_name: "",
                product_id: "",
                product_name: "",
                target_pcs: "",
                target_amount: "",
                areas: [
                  {
                    name: "A",
                    territories: [
                      {
                        name: "T1",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T2",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T3",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "B",
                    territories: [
                      {
                        name: "T4",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T5",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T6",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "C",
                    territories: [
                      {
                        name: "T7",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T8",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                  {
                    name: "D",
                    territories: [
                      {
                        name: "T9",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      months: all_months,
      current_year: new Date(),
      start_year: 2019,
      years: [],
      is_edit: false,
      all_categories: [],
      all_markets: [],
      target_details: {},
      allProducts: [],
      products: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "HVF Target", route: "/hvf/target" },
    ]);
    
    this.getProducts();
    if (this.$route.params.id) {
      this.getTarget();
    }
  },
  created() {
    localStorage.setItem("tabIndex", 16);
    this.$root.$emit("call-active-menu", 16);
    this.setYears(15);
  
    this.getCategories();
    this.getMarkets();
  },
  methods: {
    updateTargetAmount(product, territoryObj = null) {
      let selectedProduct = this.products.find(item => item.id == product.product_id);
      if(territoryObj) {
        territoryObj.target_amount = selectedProduct?selectedProduct.price * territoryObj.target_pcs:0;
      } else {
        product.target_amount = selectedProduct?selectedProduct.price * product.target_pcs:0;
      }
    },
    setCategory(product) {
      let selectedCategory = this.all_categories.find(item => item.id == product.category_id);
      product.category_name = selectedCategory?selectedCategory.name:'';
    },
    onSubmit() {
      this.$nextTick(() => {
        let url = "";
        if (this.target.id) {
          url = `${process.env.VUE_APP_HALDA_VALLAY_API}/api/reports/targets/${
            this.target.id
          }?api_token=${localStorage.getItem("api_token")}&_method=put`;
        } else {
          url = `${
            process.env.VUE_APP_HALDA_VALLAY_API
          }/api/reports/targets?api_token=${localStorage.getItem("api_token")}`;
        }
        axios.post(url, this.target).then((res) => {
          if (res.data.status_code == 200) {
            // this.getItems();
            // this.$bvModal.hide('addTarget')
            this.$snotify.success("Successfully saved");
            this.$router.push('/hvf/target');
            
            
          } else {
            this.$snotify.error(res.data.message);
          }
        });
      });
    },

    addProductsRow(channel, type = 'product') {
      if(channel.type == 'Retail' && type == 'product') {
        channel.products.push(
          {
                category_id: "",
                category_name: "",
                product_id: "",
                product_name: "",
                target_pcs: "",
                target_amount: "",
                areas: [
                  {
                    name: "A",
                    territories: [
                      {
                        name: "T1",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T2",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T3",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "B",
                    territories: [
                      {
                        name: "T4",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T5",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T6",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "C",
                    territories: [
                      {
                        name: "T7",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T8",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                  {
                    name: "D",
                    territories: [
                      {
                        name: "T9",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                ],
              }
        )
      }
      else if(channel.type == 'Retail' && type == 'market') {
        channel.markets.push({
                sales_market:"",
                target_pcs: "",
                target_amount: "",
                areas: [
                  {
                    name: "A",
                    territories: [
                      {
                        name: "T1",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T2",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T3",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "B",
                    territories: [
                      {
                        name: "T4",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T5",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T6",
                        target_pcs: "",
                        target_amount: "",
                      },
                    ],
                  },
                  {
                    name: "C",
                    territories: [
                      {
                        name: "T7",
                        target_pcs: "",
                        target_amount: "",
                      },
                      {
                        name: "T8",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                  {
                    name: "D",
                    territories: [
                      {
                        name: "T9",
                        target_pcs: "",
                        target_amount: "",
                      }
                    ],
                  },
                ],
              });
      }
      else if(type == 'market') {
        channel.markets.push({
                sales_market:"",
                target_pcs: "",
                target_amount: "",
              });
      } else {
        channel.products.push({
                category_id: "",
                category_name: "",
                product_id: "",
                product_name: "",
                target_pcs: "",
                target_amount: "",
              });
      }
    },

    removeProductRow(channel, index, type = 'product') {
      if(type == 'market') {
        channel.markets.splice(index, 1);
      } else {
        channel.products.splice(index, 1);
      }
    },

    getTarget() {
      axios
        .get(
          `${process.env.VUE_APP_HALDA_VALLAY_API}/api/reports/targets/${
            this.$route.params.id
          }?api_token=${localStorage.getItem("api_token")}`
        )
        .then((res) => {
          console.log(res.data.data);
          this.target = res.data.data
          // this.form = res.data.data;
          // this.form.month = this.convertMonth(res.data.data.month);
          // this.form.type = "sales";
          // this.categories = res.data.data.categories;
          // this.markets = res.data.data.markets;
          // this.target_details = res.data.data;
        });
    },

    addMoreMarket() {
      this.markets.push({
        sales_market: "",
        target_pcs: "",
        target_amount: "",
      });
    },
    removeMarket(index) {
      this.markets.splice(index, 1);
    },
    addMoreCategory() {
      this.categories.push({
        category_id: "",
        target_pcs: "",
        target_amount: "",
      });
    },
    removeCategory(index) {
      this.categories.splice(index, 1);
    },
    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },
    getCategories() {
      axios
        .get(
          `${
            process.env.VUE_APP_HALDA_VALLAY_API
          }/api/reports/categories?api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            this.all_categories = response.data.data;
          }
        });
    },
    getProducts(product) {
        axios
        .get(
          `${
            process.env.VUE_APP_HALDA_VALLAY_API
          }/api/reports/products?api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            this.products = response.data.data;
            let itemsArr = [];
            this.products.forEach(product => {
              if(itemsArr[product.category_id]) {
                itemsArr[product.category_id].push(product);  
              } else {
                itemsArr[product.category_id] = [product];
              }
            });
            
            this.allProducts = itemsArr;
          }
        });
    },
    getMarkets() {
      axios
        .get(
          `${
            process.env.VUE_APP_HALDA_VALLAY_API
          }/api/reports/markets?api_token=${localStorage.getItem("api_token")}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            this.all_markets = response.data.data;
          }
        });
    },
    convertMonth(month) {
      const mn = this.months.find((ite) => ite.id == month);
      return mn.text;
    },
  },
};
</script>
