<template>
  <div class="card">
    <loader-component v-if="loading" />
    <div class="card-body">
      <button
        type="button"
        class="btn btn-primary"
        @click="$router.push('/hvf/target/create')"
      >
        {{ is_edit ? "Edit" : "Add new" }} Target
      </button>
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="monnth">Month</label>
          <b-form-select
            id="month"
            v-model="filter.month"
            :options="months"
          ></b-form-select>
        </div>
        <div class="col-md-3">
          <label for="year">Year</label>
          <b-form-select id="year" v-model="filter.year" :options="years">
          </b-form-select>
        </div>

        <div class="col-md-2 mt-7">
          <button
            class="btn btn-success"
            @click="getItems()"
            v-if="year && month"
          >
            Filter
          </button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5"
          >
            <template #cell(month)="row">
              <div>
                {{ convertMonth(row.item.month) }}
              </div>
            </template>

            <template #cell(service_type)="row">
              {{
                row.item.service_type ? serviceConv[row.item.service_type] : ""
              }}
            </template>
            <template #cell(action)="row">
              <div>
                <b-dropdown
                  id="dropdown-dropleft"
                  no-caret
                  dropleft
                  text="Drop-top"
                  variant="primary"
                  class="m-2"
                >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item
                      href="#"
                      @click="$router.push('/hvf/target/' + row.item.id + '/edit')"
                    >
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
export default {
  name: "Targets",

  data() {
    let all_months = [
      {
        id: 1,
        text: "January",
        value: "January",
      },
      {
        id: 2,
        text: "February",
        value: "February",
      },
      {
        id: 3,
        text: "March",
        value: "March",
      },
      {
        id: 4,
        text: "April",
        value: "April",
      },
      {
        id: 5,
        text: "May",
        value: "May",
      },
      {
        id: 6,
        text: "June",
        value: "June",
      },
      {
        id: 7,
        text: "July",
        value: "July",
      },
      {
        id: 8,
        text: "August",
        value: "August",
      },
      {
        id: 9,
        text: "September",
        value: "September",
      },
      {
        id: 10,
        text: "October",
        value: "October",
      },
      {
        id: 11,
        text: "November",
        value: "November",
      },
      {
        id: 12,
        text: "December",
        value: "December",
      },
    ];
    return {
      items: [],
      fields: [
        {
          key: "month",
          label: "Month",
        },
        {
          key: "year",
          label: "Year",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      loading: false,
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      months: all_months,
      current_year: new Date(),
      start_year: 2019,
      years: [],
      filter: {
        month: "",
        year: "",
      },
      
      is_edit: false,
      target_details: {},
    };
  },

  created() {
    this.getItems();
    this.setYears(15);
    localStorage.setItem("tabIndex", 16);
    this.$root.$emit("call-active-menu", 16);
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "HVF Target", route: "/hvf/target" },
    ]);
  },

  methods: {
    getItems() {
      this.loading = true;
      axios
        .get(
          `${
            process.env.VUE_APP_HALDA_VALLAY_API
          }/api/reports/targets?api_token=${localStorage.getItem(
            "api_token"
          )}&limit=10&offset=0${
            this.filter.month ? "&month=" + this.filter.month : ""
          }${this.filter.year ? "&year=" + this.filter.year : ""}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.loading = false;
        });
    },

    convertMonth(month) {
      const mn = this.months.find((ite) => ite.id == parseInt(month));
      if (mn) {
        return mn.text;
      }
      return false;
    },

    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },

    clear() {
      this.filter = {};
      this.getItems();
    },
  },
};
</script>
